import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// vant-Notify样式
import 'vant/es/notify/style'

// 引入vant-Toast
import { Toast } from 'vant'
import 'vant/es/toast/style'

// 引入vant-Dialog
import { Dialog } from 'vant'
import 'vant/es/dialog/style'

// 用于设置 rem 基准值
import 'amfe-flexible'
// 适配桌面端mouse事件
import '@vant/touch-emulator'

createApp(App).use(store).use(router).use(Toast).use(Dialog).mount('#app')

// 修改Toast设置
Toast.setDefaultOptions('loading', { forbidClick: true, duration: 10000 })
