import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },

  {
    path: '/',
    name: 'layout',
    component: () => import('@/views/layout'),
    children: [
      {
        path: 'home',
        name: 'home',
        redirect: 'introduction',
        component: () => import('@/views/home')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/profile')
      },
      {
        path: 'introduction',
        name: 'introduction',
        component: () => import('@/views/introduction')
      }
    ]
  },

  {
    path: '/profile',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/profile/login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/profile/register')
      },
      {
        path: 'passwordForgotten',
        name: 'passwordForgotten',
        component: () => import('@/views/profile/passwordForgotten')
      },
      {
        path: 'testlist',
        name: 'testlist',
        component: () => import('@/views/profile/testlist'),

      },
      {
        path: 'examLog',
        name: 'examLog',
        component: () => import('@/views/profile/testlist/examLog'),

      },
      {
        path: 'testContent',
        name: 'testContent',
        component: () => import('@/views/profile/testlist/examLog/testContent'),

      },
      {
        path: 'knowledgeList',
        name: 'knowledgeList',
        component: () => import('@/views/profile/knowledgeList'),
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/profile/user'),
      },
      {
        path: 'myClass',
        name: 'myClass',
        component: () => import('@/views/profile/myClass'),
      },

      {
        path: 'classInfo',
        name: 'classInfo',
        component: () => import('@/views/profile/myClass/classInfo'),
      },
     ]
  },
   
  {
    path: '/examination',
    redirect: '/home',
    children: [
      {
        path: 'info',
        name: 'exam_info',
        component: () => import('@/views/examination/info')
      },
      {
        path: 'doing',
        name: 'exam_do',
        component: () => import('@/views/examination/doing')
      },
      {
        path: 'result',
        name: 'exam_result',
        component: () => import('@/views/examination/result')
      }
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
